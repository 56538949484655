<template>
  <b-modal
    id="exercise-modal"
    ref="exercise-modal"
    @hidden="resetModal"
    :title="
      popupType == 'detail'
        ? 'Cập nhật môn vận động'
        : popupType == 'create'
        ? 'Tạo môn vận động'
        : 'Xem môn vận động'
    "
    :hide-footer="popupType == 'view'"
  >
    <b-overlay :show="loading">
      <b-container fluid class="p-0">
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-row>
            <b-col style="min-height: 170px" class="mt-4">
              <div
                style="
                  width: 150px;
                  height: 150px;
                  background-color: #f3f6f9;
                  box-shadow: 0px 4px 4px 0px #000000 10%;
                  position: relative;
                  margin: auto;
                "
              >
                <v-file-input
                  hide-input
                  v-model="file"
                  accept="image/png, image/jpeg, image/bmp"
                  class="icon-edit"
                  @change="onUpload"
                  :disabled="disable"
                  prepend-icon="mdi-pencil-outline"
                  truncate-length="15"
                ></v-file-input>
                <v-img :src="preview" :aspect-ratio="1 / 1"></v-img>
                <p v-show="!isImageValid" class="error text-error">
                  Kích thước ảnh tối đa nhỏ hơn 200kb
                </p>
                <!-- <v-icon
                  @click="clear"
                  v-if="preview"
                  style="position: absolute; bottom: -10px; right: -10px"
                >
                  mdi-cancel</v-icon
                > -->
              </div>

              <label
                for=""
                class="d-flex flex-column align-items-center justify-content-center mt-12"
                >Icon môn vận động
                <span class="d-block">
                  Tối đa 200kb, kích thước đề xuất: 100:100px (tỷ lệ 1:1)</span
                ></label
              >
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <basic-input
                label="Mã vận động"
                name="exercise category code"
                v-model="form.code"
                :disabled="disable"
                placeholder="Nhập mã môn vận động"
                :state="validateState(`exercise category code`)"
                :invalid-feedback="errors.first(`exercise category code`)"
                v-validate="'alpha_num'"
                data-vv-as="Mã vận động"
              ></basic-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <basic-input
                :required="true"
                :disabled="disable"
                label="Môn vận động"
                name="exercise category name"
                v-validate="'required'"
                :state="validateState('exercise category name')"
                :invalidFeedback="errors.first('exercise category name')"
                v-model="form.name"
                data-vv-as="môn vận động"
                placeholder="Nhập tên môn vận động"
              ></basic-input>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <basic-text-area
                label="Ghi chú"
                placeholder="Ghi chú"
                name="note"
                :disabled="disable"
                :value.sync="form.description"
              ></basic-text-area>
            </b-col>
          </b-row>
        </form>
      </b-container>
    </b-overlay>
    <template #modal-footer>
      <div class="w-100 d-flex align-items-center justify-content-end">
        <b-button class="btn btn-plain ml-2" type="submit" @click="hideModal">
          Huỷ
        </b-button>
        <b-button
          class="btn btn-success ml-3"
          type="submit"
          @click="handleSubmit"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
          </span>
          {{ popupType == 'detail' ? 'Cập nhật' : 'Tạo' }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'ExerciseModal',
  props: {
    isEditing: {
      type: Boolean,
      default: false,
    },
    popupType: {
      type: String,
      default: 'detail',
    },
    categories: {
      type: Array,
    },
    id: {
      type: String,
      default: null,
    },
  },
  computed: {
    category() {
      return this.categories;
    },
    disable() {
      return this.popupType == 'view';
    },
  },
  data() {
    return {
      loading: false,
      snackbar: true,
      filter: {},
      file: null,
      preview: null,
      form: {
        description: null,
        code: null,
        image: null,
        name: null,
      },
      rules: [],
      isImageValid: true,
    };
  },
  watch: {
    id: {
      handler(newVal) {
        if (newVal) {
          this.loadDetail(newVal);
        }
      },
    },
  },
  methods: {
    async loadDetail(id) {
      this.loading = true;
      try {
        let { data } = await this.$api.get(`Admin/Exercise/Category/${id}`);
        this.form = data;
        this.preview = data.cover?.url;
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
        });
      } finally {
        this.loading = false;
      }
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.errors.has(ref);
      }
      return null;
    },
    onUpload() {
      //eslint-disable-next-line
      console.log('object :>> ', this.file.size);
      this.isImageValid = this.file.size < 200000;
      this.preview = URL.createObjectURL(this.file);
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (!result || !this.isImageValid) {
          return;
        }
        this.submit();
      });
    },
    async submit() {
      this.loading = true;
      let payload = new FormData();
      payload.append('code', this.form.code);
      payload.append('description', this.form.description);
      payload.append('image', this.file);
      payload.append('name', this.form.name);
      const type = this.popupType === 'detail';
      try {
        if (type) {
          payload.append('id', this.form.id);
          await this.$api.put('Admin/Exercise/Category/Input', payload);
        } else {
          await this.$api.post('Admin/Exercise/Category/Input', payload);
        }
        this.$toastr.s({
          title: 'Thành công  !',
          msg: `${
            type
              ? 'Cập nhật môn vận động thành công'
              : 'Tạo môn vận động thành công'
          } `,
        });
        this.$bvModal.hide('exercise-modal');
        this.$emit('load');
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      } finally {
        this.loading = false;
      }
    },
    updateForm() {
      this.snackbar = true;
      this.$emit('updateForm', this.form);
    },
    hideModal() {
      this.$bvModal.hide('exercise-modal');
    },
    clear() {
      this.file = null;
      this.preview = null;
      this.form.image = null;
      this.isImageValid = true;
    },
    resetModal() {
      this.form.description = null;
      this.form.code = null;
      this.form.image = null;
      this.form.name = null;
      this.file = null;
      this.preview = null;
      this.isImageValid = true;
      this.$emit('update:id', null);
    },
  },
};
</script>

<style lang="scss" scoped>
.pictureInput {
  position: relative;
}
.icon-edit {
  z-index: 100000;
  position: absolute;
  top: -30px;
  right: -6px;
  padding: 2px 6px;
  box-shadow: -1px 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  width: fit-content;
  background-color: #fff;
}
.image-upload {
  width: 138px;
  height: 138px;
  background-color: #f3f6f9;
}
.text-error {
  width: 100%;
  white-space: nowrap;
  margin: auto;
  position: absolute;
  position: absolute;
  bottom: -3rem;
  left: 25%;
  transform: translate(-50%);
  color: #ff5756;
}
</style>
